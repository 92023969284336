<template lang="">
    <div class="container-fluid shadow">
            <div class="row flex-start-center mx-4" >
                <div class="col-12">
                    <div class="flex-center align-self-center m-5 ">
                        <div class="text-center m-2 lh-1 btnhover">
                            <h2 class="mt-md-2">
                                <b class="text-dark description-xxs text-uppercase m-1" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1c_Benefits") }}  
                                </b>
                            </h2>
                            <h2 class="text-dark title-xs" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                                {{ $t("message.home1c_Elevate") }}
                            </h2>
                            <br>
                            <h2 class="mt-md-1 mx-lg-5 px-md-5">
                                <b class="text-dark description-xxs text-uppercase m-1" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1c_By") }}                            
                                </b>
                            </h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-5">
                    <div class="flex-center align-self-center" >
                        <div class="text-center mx-3 lh-1 btnhover w-100">
                            <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                                {{ $t("message.home1c_Global") }} 
                            </span>
                            <br>
                            <div class="mt-md-3">
                                <b class="text-dark description-xxxs text-uppercase m-1" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1c_Gain") }}  
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-5">
                    <div class="flex-center align-self-center" >
                        <div class="text-center mx-3 lh-1 btnhover w-100">
                            <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                                {{ $t("message.home1c_Increased") }}
                            </span>
                            <br>
                            <div class="mt-md-3">
                                <b class="text-dark description-xxxs text-uppercase m-1" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1c_Tap") }}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-5">
                    <div class="flex-center align-self-center" >
                        <div class="text-center mx-3 lh-1 btnhover w-100">
                            <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                                {{ $t("message.home1c_Enhanced") }}
                            </span>
                            <br>
                            <div class="mt-md-3">
                                <b class="text-dark description-xxxs text-uppercase m-1" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1c_Become") }}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 mb-5">
                    <div class="flex-center align-self-center" >
                        <div class="text-center mx-3 lh-1 btnhover w-100">
                            <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                                {{ $t("message.home1c_Marketing") }}
                            </span>
                            <br>
                            <div class="mt-md-3">
                                <b class="text-dark description-xxxs text-uppercase m-1" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1c_Gainaccess") }}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
 
</script>
<style scoped lang="scss">
    .btnhover:hover{
        transform: scale(1.1);
        box-shadow: rgba(124, 152, 149, 0.4) -5px 5px, rgba(124, 152, 149, 0.3) -10px 10px, rgba(124, 152, 149, 0.2) -15px 15px, rgba(124, 152, 149, 0.1) -20px 20px, rgba(124, 152, 149, 0.05) -25px 25px;
        background-color: #f5f8f8;
    } 
</style>