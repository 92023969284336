export const messages = {
    en: {
        message: {
            nav_Features: "features",
            nav_Pricing: "Pricing",
            nav_Contactus: "Contact us",
            nav_Forlocalguide: "For Local guide",
            nav_Benefits: "Benefits",
            nav_Login: "Log in",
            nav_Signup: "Sign up",
            nav_co: "USD",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1_Elevate: "Revolutionizing travel ",
            home1_with: "with personalized journeys",
            home1_Experience: "Join us on a journey to transform how you travel. Farrther seamlessly connects explorers with local guides, who offer not just companionship but expert concierge services.",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home2_Elevate: "Elevate your business",
            home2_with: "with farrther Select",
            home2_Experience: "Experience the power of global connectivity as we link customer demand worldwide with your unique offers. Let's expand your horizons together.",

            home2_Get: "Get started",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1b_steps: "Steps",
            home1b_how: "How it works",
            home1b_unlock: "Unlock the potential of your local business in the world of travel with our easy 3-step application process.",

            home1b_Application: "Application",
            home1b_Begin: "Begin by completing a short application form, sharing what makes your business special and why it's a great match for Farrther Select.",

            home1b_Verification: "Verification",
            home1b_After: "After approval, we'll work closely together to authenticate your business information. This involves confirming your location, services, and any exclusive offers for farrther Select travelers.",

            home1b_Partner: "Partner",
            home1b_Your: "Your business will become a part of the Farrther Select network. Travelers can explore your services, include your business in their travel plans, and share their experiences with other fellow travelers.",

            home1b_Learn: "Learn more",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1c_Benefits: "Benefits",
            home1c_Elevate: "Elevate Your Local Business",
            home1c_By: "By joining Farrther Select, your local business offers unique experiences, gains exposure, and fosters lasting, meaningful connections with travelers.",

            home1c_Global: "Global Exposure",
            home1c_Gain: "Gain access to a global audience of travelers actively seeking unique and authentic experiences in your area",

            home1c_Enhanced: "Enhanced Credibility",
            home1c_Become: "Become part of a select group of businesses known for their commitment to quality, safety, and excellence.",

            home1c_Increased: "Increased Revenue",
            home1c_Tap: "Tap into the lucrative tourism market by offering tailored services and unforgettable local experiences.",

            home1c_Marketing: "Marketing and Support",
            home1c_Gainaccess: "Gain access to marketing support and tools to promote your services to a wider audience.", 

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            home1d_Support: "Support",
            home1d_Everything: "Everything you need to know about the Farrther Select experience. Can’t find the answer you’re looking for? Please chat to our friendly team.",
            
            home1d_Q1: "How does the booking process work for travelers who want to experience our services through your app?",
            home1d_A1: "Travelers can easily browse and book your services through our app. They select their preferred date and time, make a reservation, and receive a confirmation.",

            home1d_Q2: "What types of businesses can become partners?",
            home1d_A2: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",

            home1d_Q3: "Do I have control over pricing and availability?",
            home1d_A3: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",

            home1d_Q4: "Is there a cost associated with becoming a partner?",
            home1d_A4: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",

            home1d_Q5: "How will partnering with your app affect my online presence and reputation?",
            home1d_A5: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",

            home1d_Q6: "How do I receive payments for bookings made through the app?",
            home1d_A6: "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            home2e_Let: "Let's become partners",
            home2e_There: "There is no cost to register. Start reaping the benefits of farrther Select!",

            home2e_Bna: "Business name*",
            home2e_Bwe: "Business website*",
            home2e_Bem: "Business number*",
            home2e_Bnu: "Business number*",
            home2e_Ain: "Additional information",

            home2e_Lum: "Leave us a message...",

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            foo_Get: "Get the app",

            foo_Stay: "Stay up to date",

            foo_Subscribe: "Subscribe",

            foo_Design: "Design amazing digital experiences that ",
            foo_create: "create more happy in the world.",

            foo_Overview: "Overview",
            foo_Benefits: "Benefits",
            foo_Features: "Features",
            foo_Pricing: "Pricing",
            foo_Help: "Help",
            foo_Privacy: "Privacy",

            foo_Stay: "Stay up to date",
            foo_Subscribe: "Subscribe",

            foo_All: "All rights reserved.",

            foo_Terms: "Terms",
            foo_Privacy: "Privacy",
            foo_Cookies: "Cookies"
        }
    },
    es: {
        message: {
            nav_Features: "Características",
            nav_Pricing: "Precios",
            nav_Contactus: "Contáctanos",
            nav_Forlocalguide: "Guia Local",
            nav_Benefits: "Beneficios",
            nav_Login: "Acceso",
            nav_Signup: "Regístrate",
            nav_co: "MX",

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1_Elevate: "Revolucionando los viajes ",
            home1_with: "con viajes personalizados",
            home1_Experience: "Únase a nosotros en un viaje para transformar su forma de viajar. Farrther conecta perfectamente a los exploradores con guías locales, que ofrecen no sólo compañía sino también servicios de conserjería expertos.",

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home2_Elevate: "Eleva tu negocio",
            home2_with: "Con farrther Select",
            home2_Experience: "Experimente el poder de la conectividad global mientras vinculamos la demanda de los clientes en todo el mundo con sus ofertas únicas. Ampliemos juntos sus horizontes.",

            home2_Get: "Comenzar",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1b_steps: "PASOS",
            home1b_how: "Cómo funciona",
            home1b_unlock: "DESBLOQUEA EL POTENCIAL DE TU NEGOCIO LOCAL EN EL MUNDO DE LOS VIAJES CON NUESTROS FÁCILES 3 PASOS PROCESO DE SOLICITUD.",

            home1b_Application: "Solicitud",
            home1b_Begin: "Comienza completando un breve formulario de solicitud, compartiendo qué hace especial a tu negocio y por qué es una excelente opción para Farrther Select.",

            home1b_Verification: "Verificación",
            home1b_After: "Después de la aprobación, trabajaremos estrechamente para autenticar la información de tu negocio. Esto implica confirmar tu ubicación, servicios y cualquier oferta exclusiva para los viajeros de Farrther Select.",

            home1b_Partner: "Socio",
            home1b_Your: "Tu negocio se convertirá en parte de la red de Farrther Select. Los viajeros pueden explorar tus servicios, incluir tu negocio en sus planes de viaje y compartir sus experiencias con otros compañeros viajeros.",

            home1b_Learn: "Más información",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1c_Benefits: "Beneficios",
            home1c_Elevate: "Potencia tu Negocio Local",
            home1c_By: "Al unirte a Farrther Select, tu negocio local ofrece experiencias únicas, gana exposición y fomenta conexiones duraderas y significativas con los viajeros.",

            home1c_Global: "Exposición Global",
            home1c_Gain: "Accede a una audiencia global de viajeros que buscan activamente experiencias únicas y auténticas en tu área.",

            home1c_Enhanced: "Credibilidad Reforzada",
            home1c_Become: "Forma parte de un grupo selecto de negocios reconocidos por su compromiso con la calidad, seguridad y excelencia.",

            home1c_Increased: "Aumento de Ingresos",
            home1c_Tap: "Ingresa al lucrativo mercado turístico ofreciendo servicios personalizados y experiencias locales inolvidables.",

            home1c_Marketing : "Marketing y Soporte",
            home1c_Gainaccess: "Accede a soporte de marketing y herramientas para promocionar tus servicios a una audiencia más amplia.",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home1d_Support: "Soporte",
            home1d_Everything: "Todo lo que necesitas saber sobre la experiencia de Farrther Select. ¿No encuentras la respuesta que buscas? Por favor, chatea con nuestro amable equipo.",

            home1d_Q1: "¿Cómo funciona el proceso de reserva para los viajeros que desean experimentar nuestros servicios a través de tu aplicación?",
            home1d_A1: "Los viajeros pueden navegar y reservar fácilmente sus servicios a través de nuestra aplicación. Seleccionan la fecha y hora preferidas, hacen una reserva y reciben una confirmación.",

            home1d_Q2: "¿Qué tipos de negocios pueden convertirse en socios?",
            home1d_A2: "Negocios y empresas relacionadas al turismo (hotelería, restaurantes, algunos servicios de transporte, servicios de salud y entretenimiento, entre otros).",

            home1d_Q3: "¿Tengo control sobre precios y disponibilidad?",
            home1d_A3: "Al convertirte en socio, podras acceder al panel de administración de servicios y experiencias, ahí podras editar tus precios, información y disponibilidad de tus servicios. ",

            home1d_Q4: "¿Hay un costo asociado con ser un socio?",
            home1d_A4: "Sí, puedes probarnos gratis durante 30 días. Si lo desea, le brindaremos una llamada de incorporación gratuita y personalizada de 30 minutos para que pueda comenzar a funcionar lo antes posible.",

            home1d_Q5: "¿Cómo afectará asociarme con tu aplicación a mi presencia y reputación en línea?",
            home1d_A5: "Nuestros usuarios calificarán su experiencia en tu negocio, así que las mejores experiencias generan mejor calificación y posteriormente mejoraran la posición de tu negocio en nuestra aplicación.",

            home1d_Q6: "¿Cómo recibo los pagos por las reservas realizadas a través de la aplicación?",
            home1d_A6: "Al ser nuestro socio tendras acceso a nuestro portal de administración, en la seccion de facturacion podras ver el estatus de tus tickets por cobrar.",
            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            home2e_Let: "Vamos a ser socios",
            home2e_There: "No hay costo de registro. ¡Comienza a disfrutar de los beneficios de Farrther Select!",

            home2e_Bna: "Nombre del negocio*",
            home2e_Bwe: "Sitio web del negocio*",
            home2e_Bem: "Número de registro del negocio*",
            home2e_Bnu: "Número de teléfono del negocio*",
            home2e_Ain: "Información adicional",

            home2e_Lum: "Déjanos un mensaje...",

            //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            foo_Get: "Obtener la aplicación",

            foo_Stay: "Mantente actualizado",

            foo_Subscribe: "Suscribirse",

            foo_Design: "Diseña experiencias digitales increíbles que ",
            foo_create: "crean más felicidad en el mundo.",

            foo_Overview: "Visión general",
            foo_Benefits: "Beneficios",
            foo_Features: "Características",
            foo_Pricing: "Precios",
            foo_Help: "Ayuda",
            foo_Privacy: "Privacidad",

            foo_Stay: "Mantente actualizado",
            foo_Subscribe: "Suscribirse",

            foo_All: "Todos los derechos reservados.",

            foo_Terms: "Términos",
            foo_Privacy: "Privacidad",
            foo_Cookies: "Cookies"

        }
    }
}