import { createRouter, createWebHistory } from 'vue-router'

// Modules
import Home from 'ROUTER/modules/home.js'
import Select from 'ROUTER/modules/select.js'
import Localguide from 'ROUTER/modules/localguide.js'

const routes = [
    ...Home,
    ...Select,
    ...Localguide
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
