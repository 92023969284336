<template>
    <div class="container-fluid shadow-lg" style="background-color: #F3F6F5; margin-top: -50px;">
        <div class="row flex-start-center" >
            <div class="col-12">
                <div class="flex-start align-self-center m-5">
                    <div class=" text-lg-start text-center m-2 lh-1">
                        <h3 class="mt-md-3">
                            <b class="text-dark description-xxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                                {{ $t("message.home1b_steps") }}
                            </b>
                        </h3>
                        <h2 class="text-dark title-xs" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                            {{ $t("message.home1b_how") }}
                        </h2>
                        <br>
                        <h2 class="mt-md-3">
                            <b class="text-dark description-xxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                                {{ $t("message.home1b_unlock") }}
                            </b>
                        </h2>
                    </div>
                </div>
            </div>
         <!----------------------------------------------CARDS----------------------------------------------------->
        <div class="col-12 col-lg-6 mb-5">
            <div class="flex-start align-self-center ms-5 border-start border-5 border-dark" >
                <div class=" text-start text-start ms-5 lh-1 ">
                    <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                        {{ $t("message.home1b_Application") }}
                    </span>
                    <br>
                    <div class="mt-md-3">
                        <b class="text-dark description-xxxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                            {{ $t("message.home1b_Begin") }}                        
                        </b>
                    </div>
                    <div class="my-md-3">
                        <b class="text-dark description-xxxs text-uppercase texthover" style="line-height: 1.8; word-spacing: 0px; ">
                            {{ $t("message.home1b_Learn") }}  

                        </b>
                        <img :src="arrow" class="mx-1 arrowpro" alt="arrow farrther" width="30" height="30" loading="lazy">
                    </div>

                </div>
            </div>
            <div class="flex-start align-self-center ms-5 border-start border-5">
                <div class=" text-lg-start text-start ms-5 lh-1">
                    <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                        {{ $t("message.home1b_Verification") }} 
                    </span>
                    <br>
                    <div class="mt-md-3">
                        <b class="text-dark description-xxxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                            {{ $t("message.home1b_After") }} 
                        </b>
                    </div>
                    <div class="my-md-3">
                        <b class="text-dark description-xxxs text-uppercase texthover" style="line-height: 1.8; word-spacing: 0px; ">
                            {{ $t("message.home1b_Learn") }}   
                        </b>
                        <img :src="arrow" class="mx-1 arrowpro" alt="arrow farrther" width="30" height="30" loading="lazy">
                    </div>
                </div>
            </div>
            <div class="flex-start align-self-center ms-5 border-start border-5">
                <div class=" text-lg-start text-start ms-5 lh-1">
                    <span class="text-dark description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                        {{ $t("message.home1b_Partner") }} 
                    </span>
                    <br>
                    <div class="mt-md-3">
                        <b class="text-dark description-xxxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                            {{ $t("message.home1b_Your") }} 
                        </b>
                    </div>
                    <div class="my-md-3">
                        <b class="text-dark description-xxxs text-uppercase texthover" style="line-height: 1.8; word-spacing: 0px; ">
                            {{ $t("message.home1b_Learn") }}  
                        </b>
                        <img :src="arrow" class="mx-1 arrowpro" alt="arrow farrther" width="30" height="30" loading="lazy">
                    </div>
                </div>
            </div>
        </div>
            <div class="col-12 col-lg-6 flex-center align-self-center mt-md-2">
                <div class="px-4 pb-4">
                    <img :src="mnpic" class="img-fluid mt-1" style="max-height: 100%; max-width: 100%;" alt="Experiencias farrther" width="571" height="560" loading="lazy">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    const cards = require("ASSETS/images/cards")
    const mnpic = require("ASSETS/images/mnpic")
    const arrow = require("ASSETS/icons/arrow")
</script>
<style scoped lang="scss" >
    .arrowpro:hover {
            animation: desplazar 1s linear infinite; /* Ajusta la cantidad de desplazamiento a la derecha */

        }
        @keyframes desplazar {
                0%, 100% {
                    transform: translateX(0); /* Estado inicial y final, sin desplazamiento */
                }
                50% {
                    transform: translateX(100%); /* Desplazamiento a la derecha en la mitad de la animación */
                }
        }
    .texthover:hover{
            border-bottom: 1px solid #133831;
            transform: scale(1.1);
            cursor: pointer;

        } 
</style>