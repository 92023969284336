<template>
    <nav class="container-fluid position-fixed shadow-sm" style="height: 60px; background-color: #ffffff; margin-top: -24px; z-index: 99;">
            <div class="flex-between h-100">
                <div class="flex-center h-100 mx-xl-4">
                    <div class="text-center text-secondary ms-4">
                        <div class="text-center text-secondary ms-xl-4 me-3 ">
                            <img :src="farthermn" class="img-fluid m-3 imghover" style="max-height: 60px;">
                        </div>
                    </div>
                </div>
                <div id="myModal" class="modalmn d-lg-none" v-show="showModal">
                    <div class="modal-content">
                        <div class="d-flex  align-items-center justify-content-center">
                            <div class="d-flex flex-column text-uppercase text-success text-center description-xs fw-bold">
                                <div class="my-2 imghover">{{ $t("message.nav_Benefits") }}</div>
                                <div class="my-2 imghover">FAQs</div>
                                <div class="my-2 imghover">{{ $t("message.nav_Contactus") }}</div>
                                <div class="my-2 imghover">
                                    <select  class="p-1 ps-2" v-model="$i18n.locale" style="border-radius: 20px;">
                                        <option v-for="locale in $i18n.availableLocales" :key="'locale-${locale}'" :value="locale" >{{ locale }}</option>
                                    </select> / {{ $t("message.nav_co") }}
                                    </div>
                                <button type="button" class="btn btn-success m-2 buttonshine" >
                                    <a class="text-decoration-none text-white description-xxs">
                                        {{ $t("message.nav_Signup") }}
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-center h-100 mx-xl-5">
                    <div style="margin-right: auto;">
                        <a class="text-center ms-2 d-lg-none d-block" @click="showModal = !showModal">
                            <img :src="navicon" class="img-fluid navicon m-2" style="max-height: 40px; z-index: 101;">
                        </a>
                    </div>
                    <div class="text-center m-xl-4 m-3 d-none d-lg-block navhover">  
                        <span class="text-dark description-xs" style="font-size: 1vw;">
                            <b>{{ $t("message.nav_Benefits") }}</b>
                        </span>
                    </div>
                    <div class="text-center m-xl-4 m-3 d-none d-lg-block navhover">  
                        <span class="text-dark description-xs" style="font-size: 1vw;">
                            <b>FAQs</b>
                        </span>
                    </div>
                    <div class="text-center m-xl-4 m-3 d-none d-lg-block navhover">  
                        <span class="text-dark description-xs" style="font-size: 1vw;">
                            <b>{{ $t("message.nav_Contactus") }}</b>
                        </span>
                    </div>
                    <div class="text-center m-xl-4 m-3 d-none d-lg-block imghover">  
                            <span class="text-dark description-xs" style="font-size: 1vw;">
                                <b>
                                <select class="p-1 ps-2" v-model="$i18n.locale" style="border-radius: 20px;">
                                    <option v-for="locale in $i18n.availableLocales" 
                                    :key="'locale-${locale}'" 
                                    :value="locale">{{ locale }}</option>
                                </select> / {{ $t("message.nav_co") }}
                                </b>
                            </span>
                        </div>   
                    <button type="button" class="btn btn-success m-2 d-none d-lg-block buttonshine" >
                        <a class="text-decoration-none text-white description-xxs">
                            {{ $t("message.nav_Signup") }}
                        </a>
                    </button>
                </div>
        </div>
    </nav>

</template>
<script setup>
import { ref } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from "vuex"

const farthermn = require("ASSETS/images/logomn") 
const navicon = require("ASSETS/icons/navicon")

const router = useRouter()
const store = useStore()

const showModal = ref(false);
const showIcon = ref(false);

</script>
<style lang="scss" scoped>
.imghover:hover{
    transform: scale(1.2);
    cursor: pointer;

} 
.navhover:hover{
    transform: scale(1.1);
    border-bottom: 1px solid #133831;
    cursor: pointer;

} 

.buttonshine:hover {
    animation-name: shine;
    animation-duration: 310ms;
    transform: scale(1.1);
    box-shadow: 6px 6px 6px #9a9a9a;
    background-color: #7C9895;   
}

.buttonshine2:hover {
    animation-name: shine;
    animation-duration: 310ms;
    transform: scale(1.1);
    box-shadow: 6px 6px 6px #9a9a9a;
    background-color: #7C9895;
}
.buttonshine2:hover a {
/* Cambio de color para el texto cuando el mouse está sobre el botón */
color: white;
}  
.navicon{
    transform: scale(1.0);
    cursor: pointer;
    border-radius: 5px;
    background-color: #ffffff;
} 
.navicon:hover{
    transform: scale(1.4);
    cursor: pointer;
}  


@keyframes shine {
0% {
    background: linear-gradient(
    30deg, 
    hsl(260, 85%, 95%) 0%, 
    #7C9895 25%
    );
}

25% {
    background: linear-gradient(
    30deg, 
    #7C9895 0%, 
    hsl(260, 85%, 95%) 25%, 
    #7C9895 50%);
}

50% {
    background: linear-gradient(
    30deg,
    #7C9895 0%, 
    hsl(260, 85%, 95%) 50%, 
    #7C9895 75%); 
}

75% {
    background: linear-gradient(
    30deg, 
    #7C9895 0%, 
    hsl(260, 85%, 95%) 75%, 
    #7C9895 100%); 
}

100% {
    background: linear-gradient(
    30deg, 
    #7C9895 0%, 
    hsl(260, 85%, 95%) 100%); 
    }
} 
.modalmn {
display: flex;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.095); /* Fondo oscuro semi-transparente */
}
.modal-content {
max-width: 100%;
max-height: 100%;
position: absolute;
top: 172px;
left: 50%;
transform: translate(-50%, -50%);
background-color: #ffffffe8; /* Fondo blanco */
padding: 10px;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.044);
}
.btn-no {
width: 50px;  
height: 50px;    
background-color: #ffffff;
color: white;
border: none;
padding: 10px 20px;
border-radius: 10px;
cursor: pointer;
}
.btn-si {
width: 70%;      
background-color: #7FDAE0;
color: white;
border: none;
padding: 10px 10px;
border-radius: 10px;
cursor: pointer;
}


</style>