<template>
    <router-view/>
</template>

<style lang="scss">
  #app {
      font-family: 'Playfair Display';
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: left;
      color: #2c3e50;
  }
  
  // Variable overrides  // Originals
  $primary: #081738;      // #0D6EFD;
  $secondary: #CCDE91;    // #6C757D;
  $success: #133831;      // #198754;
  $info: #338B85;         // #0DCAF0;
  $warning: #D29751;      // #FFC107;
  $danger: #DC3545;       // #DC3545;
  $light: #F8F9FA;        // #F8F9FA;
  $dark: #212529;         // #212529;
  
  // Then import Bootstrap
  @import "../node_modules/bootstrap/scss/bootstrap";

</style>