<template lang="">
        <div class="container-fluid shadow" style="background: #133831;">
        <div class="row flex-start-center mx-3 py-5">
            <div class="col-12 col-lg-5 mb-5">
                <div class="flex-center align-self-center" >
                    <div class="text-start mx-3 lh-1">
                        <div class="my-md-2">
                            <b class="text-white description-xxxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                                {{ $t("message.home1d_Support") }}
                            </b>
                        </div>
                        <span class="text-white description-sm" style="line-height: 1.2; word-spacing: 0px; letter-spacing: -2px;">
                            FAQs
                        </span>
                        <br>
                        <div class="mt-md-3">
                            <b class="text-white description-xxxs text-uppercase" style="line-height: 1.8; word-spacing: 0px; ">
                                {{ $t("message.home1d_Everything") }} 
                            </b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 mb-3">
                <div class="flex-center align-self-center" >
                    <div class="text-start mx-3 lh-1">
                        <div class="mt-md-3 border-sb">
                            <div class="d-flex justify-content-between">
                                <b class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; ">
                                {{ $t("message.home1d_Q1") }}
                                </b>
                                <span class="mx-2 navhover" @click="showText1 = !showText1"><img :src="down" alt="arrow farrther" width="25" height="25" loading="lazy"></span>
                            </div>
                            <p class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px;" v-show="showText1" >
                                {{ $t("message.home1d_A1") }}
                            </p>
                        </div>
                        <div class="mt-md-3 border-sb">
                            <div class="d-flex justify-content-between">
                                <b class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1d_Q2") }}
                                </b>
                                <span class="mx-2 navhover" @click="showText2 = !showText2"><img :src="down" alt="arrow farrther" width="25" height="25" loading="lazy"></span>
                            </div>
                            <p class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px;" v-show="showText2">
                                {{ $t("message.home1d_A2") }}                            
                            </p>
                        </div>
                        <div class="mt-md-3 border-sb">
                            <div class="d-flex justify-content-between">
                                <b class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1d_Q3") }}                            
                                </b>
                                <span class="mx-2 navhover" @click="showText3 = !showText3"><img :src="down" alt="arrow farrther" width="25" height="25" loading="lazy"></span>
                            </div>
                            <p class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; " v-show="showText3">
                                {{ $t("message.home1d_A3") }}                            
                            </p>
                        </div>
                        <div class="mt-md-3 border-sb">
                            <div class="d-flex justify-content-between">
                                <b class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1d_Q4") }}                            
                                </b>
                                <span class="mx-2 navhover" @click="showText4 = !showText4"><img :src="down" alt="arrow farrther" width="25" height="25" loading="lazy"></span>
                            </div>
                            <p class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; " v-show="showText4">
                                {{ $t("message.home1d_A4") }}                            
                            </p>
                        </div>
                        <div class="mt-md-3 border-sb">
                            <div class="d-flex justify-content-between">
                                <b class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px;">
                                    {{ $t("message.home1d_Q5") }}                            
                                </b>
                                <span class="mx-2 navhover" @click="showText5 = !showText5"><img :src="down" alt="arrow farrther" width="25" height="25" loading="lazy"></span>
                            </div>
                            <p class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; " v-show="showText5">
                                {{ $t("message.home1d_A5") }}                            
                            </p>
                        </div>
                        <div class="mt-md-3 border-sb">
                            <div class="d-flex justify-content-between">
                                <b class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; ">
                                    {{ $t("message.home1d_Q6") }}
                                </b>
                                <span class="mx-2 navhover" @click="showText6 = !showText6"><img :src="down" alt="arrow farrther" width="25" height="25" loading="lazy"></span>
                            </div>
                            <p class="text-white description-xxxs text-uppercase my-2" style="line-height: 1.8; word-spacing: 0px; " v-show="showText6">
                                {{ $t("message.home1d_A6") }}                            
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref } from 'vue'

    const down = require("ASSETS/icons/downicon")

    const showText1 = ref(false);
    const showText2 = ref(false);
    const showText3 = ref(false);
    const showText4 = ref(false);
    const showText5 = ref(false);
    const showText6 = ref(false);
</script>
<style lang="scss" scoped>
    .border-sb {
        border-bottom: 1px solid white;
    }
    
</style>