import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n'
import { messages } from './lang/messages'



// import CUSTOM
import 'ASSETS/scss/custom.scss'

import { Navbar, Footer } from './components/index.js'


// Create App
const i18n = createI18n({
    locale: window.navigator.language.substring (0, 2) || 'en',
    fallbackLocale: 'en',
    messages,
  })


const app = createApp(App)
app.use(store)
app.use(router)

// Templates

// Components

// Libs

// Mount
app.use(i18n)
app.mount('#app')
