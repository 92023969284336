<template >
    <div class="bg-success w-100 h-100" style="box-shadow: 0px -5px 25px rgba(0, 0, 0, 0.154);">
        <div class="row border-bottom border-1 border-white mx-5">
        <div class="col-md-5 col-12 justify-content-around d-flex align-items-end">
            <span class="text-white description-xxxs my-4 me-md-2 navhover">
                {{ $t("message.foo_Overview") }}
            </span>
            <span class="text-white description-xxxs my-4 mx-1 navhover">
                {{ $t("message.foo_Benefits") }}
            </span> 
            <span class="text-white description-xxxs my-4 mx-1 navhover">
                {{ $t("message.foo_Features") }}
            </span>        
            <span class="text-white description-xxxs my-4 mx-1 navhover">
                {{ $t("message.foo_Pricing") }}
            </span>
            <span class="text-white description-xxxs my-4 mx-1 navhover">
                {{ $t("message.foo_Help") }}
            </span> 
            <span class="text-white description-xxxs my-4 ms-1 navhover">
                {{ $t("message.foo_Privacy") }}
            </span> 
        </div>

            <div class="col-md-7 col-12 flex-end">
                <div class="text-start my-md-4 my-2 mx-md-3">  
                    <label class="form-label text-white description-xxxs" for="typeText">{{ $t("message.foo_Stay") }}</label>
                    <span class="text-white description-xs">
                        <input v-model="email" type="email" placeholder="Enter your email" class="form-control" />
                    </span>
                </div> 
                <button type="button" class="btn my-md-4 my-2 buttonshine" style="background-color: #7C9895;">
                    <a class="text-decoration-none text-white description-xxs">
                        {{ $t("message.foo_Subscribe") }}
                    </a>
                </button>
            </div>
        </div>
            <div class="row mx-5">
                <div class="col-sm-9 col-12 text-center justify-content-start d-flex align-items-center my-3">  
                    <span class="text-white description-xxxs"  style="line-height: 1.8; letter-spacing: 1px; ">
                        © 2023 Farrther Select. {{ $t("message.foo_All") }}
                    </span>
                </div> 
                <div class="col-sm-3 col-12 text-center justify-content-end d-flex align-items-center my-3">  
                    <span class="text-white description-xxxs m-2 navhover">
                        {{ $t("message.foo_Terms") }}
                    </span>
                    <span class="text-white description-xxxs m-2 navhover">
                        {{ $t("message.foo_Privacy") }}
                    </span> 
                    <span class="text-white description-xxxs m-2 navhover">
                        {{ $t("message.foo_Cookies") }}
                    </span>
                </div>
            </div>
        </div>
</template>
<script setup>

</script>
<style scoped lang="scss">
    .buttonshine:hover {
        animation-name: shine;
        animation-duration: 310ms;
        transform: scale(1.1);
        box-shadow: 6px 6px 6px #9a9a9a;
        background-color: #7C9895;

    }
    .navhover:hover{
        transform: scale(1.2);
        cursor: pointer;

    } 

    @keyframes shine {
    0% {
        background: linear-gradient(
        30deg, 
        hsl(260, 85%, 95%) 0%, 
        #7C9895 25%
        );
    }

    25% {
        background: linear-gradient(
        30deg, 
        #7C9895 0%, 
        hsl(260, 85%, 95%) 25%, 
        #7C9895 50%);
    }

    50% {
        background: linear-gradient(
        30deg,
        #7C9895 0%, 
        hsl(260, 85%, 95%) 50%, 
        #7C9895 75%); 
    }

    75% {
        background: linear-gradient(
        30deg, 
        #7C9895 0%, 
        hsl(260, 85%, 95%) 75%, 
        #7C9895 100%); 
    }

    100% {
        background: linear-gradient(
        30deg, 
        #7C9895 0%, 
        hsl(260, 85%, 95%) 100%); 
        }
    }  
    
</style>